.row::after {
  content: "";
  clear: both;
  display: table;
}

.form-login {
  display: flex;
  justify-content: center;
}

.logo-center {
  width: 300px !important;
}

.login-field {
  justify-content: space-between;
  padding-bottom: 15;
}

.login-button-form {
  display: flex;
  justify-content: center;
}

.btn-submit {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.login-head {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.copy-foot {
  color: #66c6f1;
  position: absolute;
  bottom: 10 !important;
  text-align: center !important;
}

.footer-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
  color: #e94e1a;
  //margin-right: 15;
}

.side-backgr-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100vh;
}

[class*="ant-col"] {
  float: left;
  padding: 15;
}

/* For mobile phones: */
@media only screen and (min-width: 769px) {
  /* For desktop: */
  .side-image {
    display: flex;
  }

  .logo-center {
    width: 400px !important;
  }

  .side-backgr-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100vh;
  }

  .side-backgr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
  }

  .aal-7 {
    float: left;
    width: 100% !important;
    padding: 5px;
    column-span: 7 !important;
  }

  .aal-17 {
    float: left;
    width: 100% !important;
    padding: 5px;
    column-span: 17 !important;
  }

  .login-form {
    width: 400px;
    margin: 1rem 0 0 0;
  }
  .copy-foot {
    color: #66c6f1;
    text-align: center !important;
  }
}
