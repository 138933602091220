.btnSuccess {
  background-color: #2ecc71;
  border-color: #27ae60;
  border-radius: 50px !important;
}
.btnInfo {
  background-color: #1abdaf;
  border-color: #16a195;
  border-radius: 50px !important;
}
.btnWarning {
  background-color: #f1c40f;
  border-color: #f39c12;
  border-radius: 50px !important;
}
.btnDefault {
  background-color: #ecf0f1;
  border-color: #bdc3c7;
  border-radius: 50px !important;
}
.btnInverse {
  background-color: #95a5a6;
  border-color: #7f8c8d;
  border-radius: 50px !important;
}
.btnOrange {
  background-color: #f08f65;
  border-color: #f39c12;
  border-radius: 50px !important;
}
.btnDanger {
  background-color: #e74c3c;
  border-color: #c0392b;
  border-radius: 50px !important;
}
.btnDark {
  background-color: #34495e;
  border-color: #34495e;
  border-radius: 50px !important;
}
.btnWhite {
  background-color: transparent;
  border-color: #ffffff;
  border-radius: 50px !important;
}
.btnWhiteBorder {
  background-color: #ffffff;
  border-color: #c2c2c2;
  border-radius: 50px !important;
}
.btnSecundary {
  background-color: #0f70b7;
  border-color: #0d5e97;
  border-radius: 50px !important;
}
.btnBlueLight {
  background-color: #3498db;
  border-color: #3187c0;
  border-radius: 50px !important;
}
.btnBorder {
  border-radius: 50px !important;
}
