.rfipbtn {
  width: 90px !important;
  height: 32px !important;
}

.rfipbtn {
  min-height: 32px !important;
}

.rfipbtn__current {
  width: 20px !important;
  height: 32px !important;
  flex: 0px !important;
  border: none !important;
  margin-top: -2 !important;
}

.rfipbtn__button {
  width: 27px !important;
  height: 30px !important;
}
