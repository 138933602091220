.main-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(#ffffff, 1);
  height: 50px;
  width: 100vw;
  top: 0px;
  left: 0px;

  .img-logo-title {
    height: 30px;
  }

  .li_options {
    display: inline;
    margin-right: 5px;
    margin-left: 15px;
    padding-top: -10px;

    .icn-cart {
      padding-top: -15px;
    }
  }

  .avatar-header {
    size: small !important;
  }

  .icon-header {
    font-size: 20px !important;
  }
}

/* For mobile phones: */
[class*="col-"] {
  width: 100%;
}
.col-3 {
  margin-right: 0px !important;
  //padding-right: -20px !important;
}
.col-9 {
  width: 100%;
  margin-left: 0px !important;
  //padding-right: -20px !important;
}

//Desktop
@media only screen and (min-width: 769px) {
  /* For desktop: */
  .col-1 {
    width: 8.33%;
  }
  .col-2 {
    width: 16.66%;
  }
  .col-3 {
    width: 25%;
    padding-top: 2px !important;
  }
  .col-4 {
    width: 33.33%;
  }
  .col-5 {
    width: 41.66%;
  }
  .col-6 {
    width: 50%;
  }
  .col-7 {
    width: 58.33%;
  }
  .col-8 {
    width: 66.66%;
  }
  .col-9 {
    width: 75%;
  }
  .col-10 {
    width: 83.33%;
  }
  .col-11 {
    width: 91.66%;
  }
  .col-12 {
    width: 100%;
  }
  .main-header {
    display: flex;
    justify-content: space-between;
    background-color: rgba(#ffffff, 1);
    width: 100vw;
    height: 50px;
    top: 0px;
    left: 0px;

    .img-logo-title {
      height: 30px;
      position: absolute !important;
      top: 0px !important;
      left: 40px !important;
    }

    .ul_options {
      margin-bottom: 0px !important;
      padding: 0px !important;
    }

    .li_item {
      display: inline;
      margin-right: 10px;
      marging-top: 10px !important;
    }

    .space-menu {
      height: 24px !important;
      margin: 0px !important;
      padding: 0px !important;
    }

    .icon-header {
      font-size: 22px !important;
    }
  }
}
