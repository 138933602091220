.row::after {
  content: "";
  clear: both;
  display: table;
}

.site-drawer-render-in-current-wrapper {
  position: relative;
  padding: 0px;
  margin-top: 1px;
  overflow: hidden;
  background: white;
  border: 1px solid #ebedf0;
  border-radius: 2px;
}

.ant-card-head {
  padding: 0px !important;
  height: 30px !important;
  //padding-left: 10px !important;
  //padding-right: 10px !important;
  //padding-top: 5px !important;
  //padding-bottom: 5px !important;
}

.ant-card-head-title {
  padding: 0px !important;
  //padding-top: 5px !important;
  //padding-bottom: 5px !important;
  font-size: 12px !important;
}

.ant-card-body {
  padding: 0px !important;
  padding-left: 0px !important;
}

.ant-tabs-nav-wrap {
  display: flex;
  justify-content: center;
}

.ant-card-actions .li {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.img-logo {
  display: none;
}

.sider-dashborad {
  display: none;
}
.h2-tile {
  font-size: 15px !important;
}
.h4-tile {
  font-size: 12px !important;
  margin-bottom: 0px !important;
}

.h5-tile {
  font-size: 12px !important;
}
.ant-col .ant-col-4 {
  width: 33% !important;
}

.site-card-wrapper {
  flex-direction: column !important;
}

@media only screen and (min-width: 1024px) {
  /* For desktop: */
  .img-logo {
    display: block;
    width: 150px !important;
  }
  .sider-dashborad {
    display: block;
  }
  .h2-tile {
    font-size: 14px !important;
  }
  .h4-tile {
    font-size: 12px !important;
    margin-bottom: 0px !important;
  }
  .h5-tile {
    font-size: 16px !important;
  }

  .ant-card-body {
    //padding-left: 10px !important;
    //padding-right: 10px !important;
  }
  .site-card-wrapper {
    flex-direction: row !important;
  }
}

@media only screen and (max-width: 1699px) {
  /* For desktop: */
  .img-logo {
    display: block;
    width: 150px !important;
  }
  .sider-dashborad {
    display: block;
  }
  .icon-tile {
    display: block !important;
  }
  .h2-tile {
    font-size: 18px !important;
  }
  .h4-tile {
    font-size: 16px !important;
    margin-bottom: 0px !important;
  }
  .h5-tile {
    font-size: 16px !important;
  }

  .ant-card-body {
    //padding-left: 10px !important;
    //padding-right: 10px !important;
  }
  .site-card-wrapper {
    flex-direction: row !important;
  }
}

//Desktop
@media only screen and (min-width: 1700px) {
  /* For desktop: */
  .img-logo {
    display: block;
    width: 150px !important;
  }
  .sider-dashborad {
    display: block;
  }
  .icon-tile {
    display: block !important;
  }
  .h2-tile {
    font-size: 24px !important;
  }
  .h4-tile {
    font-size: 20px !important;
    margin-bottom: 0px !important;
  }
  .h5-tile {
    font-size: 16px !important;
  }

  .ant-card-body {
    //padding-left: 20px !important;
    //padding-right: 20px !important;
  }
  .site-card-wrapper {
    flex-direction: row !important;
  }
}
