.main-content-b {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90vw;
}

.ant-table-thead .ant-table-cell {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.ant-table-tbody .ant-table-row .ant-table-cell {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.ant-btn-ghost {
  background: rgba(#66c6f1, 1);
  background-color: rgba(#66c6f1, 1);
  border-color: rgba(#66c6f1, 1);
}

.ant-dropdown-menu-item {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: "#6A3E98" !important;
  margin-left: 15px !important;
  margin-right: 15px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
