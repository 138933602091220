.main-content-b {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90vw;
}

.ant-input-number-input {
  text-align: right !important;
}

.ant-input-number-handler-wrap {
  visibility: hidden;
}
