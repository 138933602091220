.product {
  padding: 0;
  min-height: 350px !important;

  .ant-card {
    margin: 15px;
    min-height: 350px !important;

    .ant-card-cover {
      padding: 10px;
    }

    .ant-card-actions {
    }

    .ant-card-body {
      padding: 5px !important;
      min-height: 97px !important;

      .ant-card-meta-title {
        color: #f35b2b;
        text-align: center;
        margin-bottom: 0px !important;

        .prd-price {
          margin-bottom: 0px !important;
        }
      }

      .prd-descr {
        margin-bottom: 0px !important;
        text-transform: capitalize !important;
      }
    }

    .btn {
      width: 100%;
      background-color: transparent;
      color: #0098d3;
      border-color: #0098d3;
      border-radius: 50px;

      &:hover,
      &:active,
      &:focus {
        background-color: #0098d3 !important;
        border-color: #0098d3;
        color: #fff;
        box-shadow: none !important;
      }
    }
  }
}
